import { render, staticRenderFns } from "./publicJobDetail.vue?vue&type=template&id=2d68c66f&scoped=true"
import script from "./publicJobDetail.vue?vue&type=script&lang=js"
export * from "./publicJobDetail.vue?vue&type=script&lang=js"
import style0 from "./publicJobDetail.vue?vue&type=style&index=0&id=2d68c66f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d68c66f",
  null
  
)

export default component.exports