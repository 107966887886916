<template>
  <div class="job-board-list-card cursor-pointer">
    <div class="title job-card-title d-flex justify-content-between">
      <p class="job-card-title" style="line-height: 1">
        {{ jobTitle }} &nbsp;|&nbsp; {{ jobId }}
      </p>
      <div class="d-flex">
        <div v-if="featured" class="featured">
          <span>Featured</span>
        </div>
        <div
          v-if="isAdmins"
          :class="`status-${published ? 'publish' : 'draft'}`"
        >
          <span>{{ published ? "Published" : "Draft" }}</span>
        </div>
      </div>
    </div>
    <p class="posted-time">{{ postedTime }}</p>
    <div class="location">
      <div>
        <CIcon name="cil-location-pin" />&nbsp; <span>{{ comments }}</span
        >&nbsp;
        <div class="organisation">
          <CIcon name="cil-hospital" />&nbsp; <span>{{ organisation }}</span
          >&nbsp;
        </div>
      </div>
    </div>
    <p class="content text-truncate">{{ jobDescription }}</p>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["item", "index"],
  computed: {
    ...mapGetters(["getRoleScopes"]),
    featured() {
      return this.item.featured || "";
    },
    isAdmins() {
      return (
        this.getRoleScopes.length &&
        [
          "system_admin",
          "system_recruiter",
          "customer_admin",
          "customer_recruiter",
        ].includes(this.getRoleScopes[0])
      );
    },
    published() {
      return this.item.published || "";
    },
    jobTitle() {
      return this.item?.job_title || "--";
    },
    comments() {
      return this.item?.location?.comments || "--";
    },
    organisation() {
      return this.item?.organisation?.name || "";
    },
    postedTime() {
      let created = moment(this.item?.created_on);
      let today = moment();
      return today.diff(created, "days") !== 0
        ? `${today.diff(created, "days")} days ago`
        : "New";
    },
    jobDescription() {
      if (this.item?.advert_description) {
        return this.item?.advert_description.replace( /(<([^>]+)>)/ig, '').replace(/&nbsp;/gi,'');
      } else {
        return this.item?.organisation?.description.replace( /(<([^>]+)>)/ig, '').replace(/&nbsp;/gi,'');
      }
    },
    jobId() {
      return this.item?.job_id;
    },
  },
};
</script>
<style scoped lang="scss">
.cursor-pointer {
  cursor: pointer;
}
.job-card-title {
  margin-bottom: 4px;
  color: #00626a !important;
}
.posted-time {
  color: $orange;
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: bold;
}
.status-draft {
  background-color: $body-bg;
  border: 1px solid $body-bg;
  padding: 3px 10px;
  border-radius: 6px;
  color: $color-black;
  min-width: 70px;
  text-align: center;
  max-height: 18px;
}
.status-publish {
  background-color: $green;
  border: 1px solid $green;
  padding: 3px 10px;
  border-radius: 6px;
  color: $white;
  min-width: 70px;
  text-align: center;
  max-height: 18px;
}
.featured {
  background-color: #dd3651;
  border: 1px solid #dd3651;
  padding: 3px 10px;
  margin: 0px 3px;
  border-radius: 6px;
  color: $white;
  min-width: 60px;
  text-align: center;
  max-height: 18px;
}
</style>