var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"job-issuer-home"},[(_vm.isPublicJobIssuerBoardFetching)?_c('LoadingOverlay'):_vm._e(),_c('CRow',{staticClass:"mb-4"},[_c('CCol',{attrs:{"md":"10"}}),_c('CCol',[_c('div',{staticClass:"d-flex flex-column"},[_c('CButton',{staticClass:"btn-primary",attrs:{"title":"Add Job"},on:{"click":_vm.navigateToAddPublicJob}},[_vm._v("Add Job")])],1)])],1),_c('div',{staticClass:"d-flex flex-row"},[((!_vm.isPublicJobIssuerBoardFetching && !_vm.getPublicJobIssuerBoard.length))?_c('div',{staticClass:"col-md-12 d-flex flex-column mt-5"},[_c('h4',{staticClass:"text-center"},[_vm._v(" No jobs found, Please add a Job ")])]):_vm._e(),(
        !(!_vm.isPublicJobIssuerBoardFetching && !_vm.getPublicJobIssuerBoard.length)
      )?_c('div',{staticClass:"job-board-list col-md-4",attrs:{"id":"job-board-body"}},[_c('div',[(false)?_c('h6',{staticClass:"count"},[_vm._v(" Total Jobs: 0"),_c('span',{staticClass:"pl-3"}),_vm._v(" Search Results: 0 ")]):_vm._e()]),_vm._l((_vm.getPublicJobIssuerBoard),function(job,index){return _c('job-card',{key:index,class:_vm.getSelectedPublicJobIssuerBoard &&
          _vm.getSelectedPublicJobIssuerBoard.job_id == job.job_id
            ? 'highlight'
            : 'disabled',attrs:{"index":index,"item":job},nativeOn:{"click":function($event){return _vm.jobCardSelectedCallback(job)}}})}),(_vm.noMoreDataFromPublicJobIssuerBoard)?_c('div',{staticClass:"no-more-data"},[(_vm.getPublicJobIssuerBoard.length > 0)?_c('p',[_vm._v("No More Data!")]):_vm._e()]):_vm._e(),(!_vm.noMoreDataFromPublicJobIssuerBoard)?_c('div',{staticClass:"d-flex justify-content-around align-items-center has-loading-overlay",staticStyle:{"height":"200px"}},[(_vm.isPublicJobIssuerBoardFetching)?_c('LoadingOverlay'):_vm._e()],1):_vm._e()],2):_vm._e(),(
        _vm.getPublicJobIssuerBoard.length > 0 || _vm.isPublicJobIssuerBoardFetching
      )?_c('div',{staticClass:"job-board-detail col-md-8"},[_c('job-detail',{attrs:{"getSelectedJobBoard":_vm.getSelectedPublicJobIssuerBoard}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }